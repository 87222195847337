<template>
  <div>
    <list-template
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      :isDownload="true"
      @onAdd="$router.push('./add')"
      @onBatchImport ="handBatchImport "
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange" />
    <Dialog :visible.sync="visible" title="导入数据" size="small" buttonAlign="center" submitText="关闭" :showCancelButton="false" :showFooter="true" :closeOnClickModal="false">
      <div class="tw">请下载模板后，在模板内填充数据，再上传数据</div>
      <div class="px-2">
        <LineWrapper labelWidth="100rem" labelColor="#222">
          <LineItem label="下载模板">
            <el-button size="small" icon="el-icon-download" @click="handleDownFile">下载</el-button>
          </LineItem>
          <LineItem label="上传数据">
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSelectFile">上传</el-button>
          </LineItem>
        </LineWrapper>
      </div>
      <!-- <div class="dialog-btn pt-1">
        <el-button type="primary" @click="handleClose">关闭</el-button>
      </div> -->
    </Dialog>
  </div>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { changeOption } from "@/utils";
import Dialog from "@/components/common/Dialog";
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import { zsGet, fileDownload } from "@/api";
import { handleChooseFile } from "@/utils";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  components: { Dialog, LineItem, LineWrapper },
  mixins: [tableListMixin],
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      zsGet('/crm/thread-config').then(res => {
        const { recruit_status = [], recruit_school = [] } = res
        this.searchConfig[1].options = changeOption({ list: recruit_status, label: 'name', value: 'id' })
        this.searchConfig[2].options = changeOption({ list: recruit_school, label: 'school_name', value: 'id' })
      })
    },
    getData() {
      this.getDataApi('/zhaosheng/api/crm/recruit-list')
    },
    onReset() {},
    handleClick(row) {
      this.$router.push(`./detail?id=${row.id}`)
    },
    handleClose() {
      this.onBeforeClose(() => {
        this.visible = false
      })
    },
    onBeforeClose(done) {
      done()
    },
    handBatchImport () {
      this.visible = true
    },
    handleSelectFile() {
      handleChooseFile({
        api: '/zhaosheng/api/crm/import',
        show: true,
        onSuccess: (res) => {
          this.visible = false
          this.getData()
          const { success, fail } = res
          this.$message.success({
            message: `成功导入【${success}】条数据，共有【${fail}】条错误数据无法导入`,
            duration: 5000
          })
        }
      })
    },
    handleDownFile() {
      const params = { name: '客户资源池模板', type: 'xlsx' }
      const api = `${process.env.VUE_APP_URL_OSS}preview/recruit_students_thread.xlsx`
      fileDownload(api, {}, params).then(res => {
        this.visible = false
      })
    }
  },
  data() {
    return {
      code: '',
      visible: false,
      searchConfig: [
        {
          tag: 'input',
          placeholder: '请输入手机号',
          prop: 'phone',
          type: 'number'
        },
        {
          tag: 'select',
          placeholder: '请选择跟进状态',
          prop: 'status',
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择筛选咨询校区',
          prop: 'school',
          options: []
        },
      ],
      tableConfig: [
        {
          prop: 'status_name',
          label: '跟进状态',
        },
        {
          prop: 'name',
          label: '客户姓名',
          render(row) {
            return row.name || row.import_name
          }
        },
        {
          prop: 'phone',
          label: '手机号',
        },
        {
          prop: 'relationship_name',
          label: '客户与学生的关系',
          render(row) {
            return row.real_relationship || row.relationship_name
          }
        },
        {
          prop: 'school_name',
          label: '咨询校区',
        },
        {
          prop: 'creator',
          label: '线索归属人',
        },
        {
          prop: 'created_at',
          label: '线索录入时间',
        },
        {
          label: '操作',
          width: '100px',
          handle: true,
          fixed: 'right',
          render() {
            return ['跟进']
          }
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &-code {
    width: 280px;
    height: 280px;
    margin: 10px auto;
    background-color: darkgray;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-btn {
    text-align: center;
  }
}
</style>
